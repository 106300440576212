import React from "react";

import planeImg from "../../img/plane.svg";
import planeStart from "../../img/plane-start.svg";
import planeEnd from "../../img/plane-end.svg";

const PriceSection = () => {
  return (
    <section className="landing-price is-rounded-bottom" id="price">
      <img src={planeStart} alt="" className="landing-price__plane-start" />
      <img src={planeEnd} alt="" className="landing-price__plane-end" />
      <div className="container">
        <h2 className="title">How much does it cost?</h2>
        <div className="landing-price__items">
          {/* <div className="landing-price__item">
            <div className="landing-price__item-value">
              5.5$
            </div>
            <div className="landing-price__item-label">
              США / 0,5кг
            </div>
          </div> */}
          <div className="landing-price__item is-main">
            <div className="landing-price__item-label">
              <img src={planeImg} alt="Price plane" />
              Delivery
            </div>
            <div className="landing-price__item-value">
              7-12
              <span>days</span>
            </div>
          </div>
          <div className="landing-price__item">
            <div className="landing-price__item-value">£20 ($25)</div>
            <div className="landing-price__item-label">China/2kg</div>
          </div>
        </div>
        <div className="landing-price__buy">
          <div className="landing-price__buy-title">
            Service “Buy product for me”
          </div>
          <div className="landing-price__buy-value">
            <span>10%</span>
            of order value
          </div>
        </div>
      </div>
    </section>
  );
};

export default PriceSection;
